<template>
  <div v-show="numeroArquivos">
    <div class="text-center display-1 font-weight-normal mb-6">
      {{ numeroArquivos }} arquivos enviados, {{ numeroMapeados }} mapeados e
      {{ numeroNaoMapeados }} não mapeados
    </div>

    <v-alert
      v-show="numeroEntidadesAusentes"
      class="py-2"
      outlined
      type="error"
    >
      <span>{{ msgEntidadesAusentes }}</span>
    </v-alert>
    <v-alert
      v-show="numeroArquivos > qtdEntidades"
      class="py-2"
      outlined
      type="error"
    >
      <span>A importação do INDGER deve conter apenas {{ qtdEntidades }} arquivos</span>
    </v-alert>

    <v-text-field
      id="search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-8 mt-4 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="importacoes-indger-table"
      :headers="[
        {
          text: 'Arquivo',
          value: 'arquivo'
        },
        {
          sortable: false,
          text: 'Entidade',
          value: 'entidade'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          align: 'center'
        }
      ]"
      :items="arquivos"
      :search.sync="search"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      disable-pagination
      hide-default-footer
      dense
      no-data-text="Nenhum arquivo encontrado"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="800"
    >
      <template v-slot:[`item.entidade`]="{ item }">
        <mapeamento-arquivo
          :entidades="entidades"
          :arquivo="item"
          @mapeamento:atualizado="handleMapeamentoAtualizado"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray"
              min-width="0"
              class="px-1"
              fab
              icon
              x-small
              v-on="on"
              v-bind="attrs"
              @click="handleMapeamentoArquivoApagado(item)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Clique aqui para excluir o arquivo {{ item.arquivo }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import ImportacoesIndgerService from '@/services/ImportacoesIndgerService';

export default {
  components: {
    MapeamentoArquivo: () => import('@/components/administracao/importacoes-indger/MapeamentoArquivo.vue')
  },
  props: {
    arquivos: {
      type: Array,
      required: true,
      default: () => []
    },
    extensoesPermitidas: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      entidades: [],
      mapeamentos: {},
      search: ''
    };
  },
  computed: {
    arquivosRelevantes() {
      return this.arquivos
        .filter((arquivo) =>
          this.extensoesPermitidas.includes(arquivo.extensao.toLowerCase())
        )
        .sort((a, b) => {
          if (a.arquivo > b.arquivo) {
            return 1;
          }
          if (a.arquivo < b.arquivo) {
            return -1;
          }
          return 0;
        });
    },
    qtdEntidades() {
      return this.entidades.length;
    },
    numeroArquivos() {
      return this.arquivosRelevantes.length;
    },
    numeroArquivosDados() {
      return this.arquivosRelevantes.reduce((accumulator, currentValue) => {
        let extensao = currentValue.extensao.toLowerCase();
        return (accumulator += extensao === 'csv' ? 1 : 0);
      }, 0);
    },
    entidadesMapeadas() {
      const entidades = Object.values(this.mapeamentos);
      const entidadesUnicas = [...new Set(entidades)];

      return entidadesUnicas.filter((entidade) => entidade !== null && entidade !== undefined);
    },
    entidadesAusentes() {
      return this.entidades.filter(
        (entidade) => !this.entidadesMapeadas.includes(entidade)
      );
    },
    numeroEntidadesAusentes() {
      return this.entidadesAusentes.length;
    },
    numeroMapeados() {
      const entidades = Object.values(this.mapeamentos);
      return entidades.filter((entidade) => entidade !== null && entidade !== undefined).length;
    },
    numeroNaoMapeados() {
      const entidades = Object.values(this.mapeamentos);
      return entidades.filter((entidade) => entidade === null || entidade === undefined).length;
    },
    msgEntidadesAusentes() {
      let msg = '';
      if (this.numeroEntidadesAusentes === 1) {
        msg = 'Existe uma entidade ausente em seu mapeamento: ';
      } else if (this.numeroEntidadesAusentes > 1) {
        msg = `Existem ${this.numeroEntidadesAusentes} entidades ausentes em seu mapeamento: `;
      }
      return msg === '' ? '' : msg + this.entidadesAusentes.join(', ') + '.';
    }
  },
  mounted() {
    this.getEntidadesIndger();
  },
  methods: {
    getEntidadesIndger() {
      ImportacoesIndgerService.getEntidadesIndger()
        .then((response) => (this.entidades = response))
        .catch((error) => console.error(error));
    },
    handleMapeamentoAtualizado($event) {
      const { arquivoId, entidade } = $event;
      this.$set(this.mapeamentos, arquivoId, entidade);
      this.emiteEventoMapeamentoAtualizado();
    },
    handleMapeamentoArquivoApagado(item) {
      this.$emit('mapeamento:apagar-arquivo', {
        nome: item.arquivo,
        id: item.id
      });
    },
    emiteEventoMapeamentoAtualizado() {
      this.$emit('mapeamento:atualizado', {
        qtdEntidades: this.qtdEntidades,
        mapeamentos: this.mapeamentos,
        numeroEntidadesAusentes: this.numeroEntidadesAusentes,
        numeroArquivos: this.numeroArquivos,
        numeroArquivosDados: this.numeroArquivosDados,
        numeroMapeados: this.numeroMapeados,
        numeroNaoMapeados: this.numeroNaoMapeados
      });
    }
  }
};
</script>
